<template>
  <div>
    <v-row>
      <v-col lg="12">
          <register-weigth-discart-lot-production
           :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100">         >
          ></register-weigth-discart-lot-production>
                </v-col>
    </v-row>
  </div>
</template>

<script>
import RegisterWeigthDiscartLotProduction from './RegisterWeigthDiscartLotProduction.vue';
 
export default {
  components: {
    RegisterWeigthDiscartLotProduction
  },

   data() {
    return {
          
    };
  },

  methods: {
   rowSelected(rows) {
  },
   
  },
};
</script>
