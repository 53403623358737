<template>
  
    <s-crud 
    title="Registro de Descarte de Lote de Producción"
    :config="configdiscart"
    @save="save($event)"
    add
    remove   
    search-input  
    ref="crud"
    @rowSelected="rowSelected($event)"
    :filter="filter"
    >  
   <template scope="props">
               <v-row>    
             
                <v-container v-if="props.item != null">
                <v-col cols="3" class="s-col-form">
                    <s-select-lot-production  v-model="ObjectItem"  return-object label="Lote - N.Jabas" :estado="5" >>
                    </s-select-lot-production>  
                  </v-col>        
                 </v-container>
                 <!--    -->
                 <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="P. Neto" v-model="ObjectItem.WbbNetWeight"   ></s-text>
                  </v-col>  
                  <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                    <s-text label="P. Descarte" v-model="pdiscart" @input="sumar()"></s-text>
                  </v-col>  
                   <v-col cols="6" class="s-col-form" sm="4" md="8" lg="2">
                        <s-text label="P.Exportación" v-model="props.item.DisWeightExp" v-text="pexport" number></s-text>
                  </v-col>     
                 
                  </v-row>
 
          </template>
   </s-crud>

</template>


<script>
import _service from "@/services/FreshProduction/PrfLotProductionDiscartService.js";
import SText from '../../../../components/Utils/SText.vue';
import SSelectLotProduction from '@/components/FreshProduction/LayetteIncidence/SSelectLotProduction.vue';


export default {
  name: "RegisterWeigthDiscarLotProduction",
  components: { SText,SSelectLotProduction  },
  props: {
   addNoFunction: { type: Boolean, default: false },
  },

  data: () =>({
    DisLprID: 0,
     pexport:0,
     pdiscart:0,
     ObjectItem:{}, 

  }),
  computed: {
        filter() {
            return{
            };
         },
   
    configdiscart() {
      return {
        model: {       
          DisLprID: "ID",
        }, 
        service: _service,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "DisLprID", sortable: false },
               { text: "Lote Producción", value: "RcfID", sortable: false },
              { text: "P. Neto", value: "DisNetWeight", sortable: false }, 
              { text: "P. Exportación", value: "DisWeightExp", sortable: false }, 
              { text: "P. Descarte", value: "DisWeigthDiscart", sortable: false },          
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
              { text: "ID", value: "DisLprID", sortable: false },
              { text: "Lote Producción", value: "RcfID", sortable: false },
              { text: "P. Neto", value: "DisNetWeight", sortable: false }, 
              { text: "P. Exportación", value: "DisWeightExp", sortable: false }, 
              { text: "P. Descarte", value: "DisWeigthDiscart", sortable: false },                
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {
    sumar(){
      this.pexport=this.ObjectItem.WbbNetWeight-this.pdiscart;
    },
 
 Initialize() {      
     
    },
    edit(item) {
      this.dialogEdit = true;    
    },

    rowSelected(item) {
     this.dialogEdit = true;
     this.DisLprID = 0;
      if (item.length > 0) this.DisLprID = item[0].DisLprID;
      this.$emit("rowSelected", item);      
    },

    save(item) {
     item.RcfID=this.ObjectItem.RcfID;
       item.DisNetWeight=this.ObjectItem.WbbNetWeight;
       item.SecStatus = 1;
       item.DisWeightExp=this.pexport;
       item.DisWeigthDiscart=this.pdiscart;
       if (item.DisWeightExp == 0) {
        this.$fun.alert("Ingrese el peso de exportacion", "warning");
        return;
      }
      if (item.DisNetWeight == 0) {
        this.$fun.alert("Ingrese el peso neto", "warning");
        return;
      }
      console.log(item);
      item.save();
    },    
  },
  };
</script>
